.navbar-nav {
	flex-direction: row !important;
}

.navbar-nav .nav-link {
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.navbar-nav .nav-item:last-child .nav-link {
	padding-right: 0;
}

.navbar-brand img {
	border-radius: 5px;
}

#hero {
	padding-top: 85px;
	height: 350px;
	position: relative;
	background-image: url("granola-800.jpg");
	background-repeat: no-repeat;
	background-size: cover;
}

#hero .overlay {
	background-color: rgba(0, 0, 0, 0.75);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

#hero h1 {
	font-family: 'Dancing Script', cursive;
	font-size: 60px;
	margin-bottom: 0;
}

#hero p {
	margin-top: 20px;
	font-size: 16px;
	font-weight: 300;
}

#buy-button-anchor {
	height: 1px;
	width: 100%;
}

#blurb {
	padding: 50px 0;
}

.blurb-box {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.blurb-item {
	padding: 30px;
	display: flex;
	flex-direction: row;
}

.blurb-icon {
	padding: 15px;
	height: 70px;
	width: 70px;
	color: #bebebe;
	border: 1px solid #bebebe;
	border-radius: 40px;
	margin-right: 30px;
}

.blurb-description {
	width: auto;
	color: #5a5a5a;
	text-align: left;
	display: inline-block;
	vertical-align: top;
}

.blurb-description h6 {
	font-size: 18px;
	color: #2f2f2f;
}

#buy-button h1 {
	font-family: 'Dancing Script', cursive;
	font-size: 60px;
	width: 100%;
	text-align: center;
	color: #41abd3;
	background-color: rgb(235, 237, 240);
	padding-top: 50px;
	padding-bottom: 50px;
	margin-bottom: 50px;
}

#shop-now-btn-lg {
	display: none;
}
#shop-now-btn-sm {
}

/*.shopify-buy__product {*/
/*	margin-bottom: 20px;*/
/*}*/

#footer {
	height: 80px;
	line-height: 80px;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
	#hero {
		background-image: url("granola-800.jpg");
	}
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
	#hero {
		padding-top: 135px;
		height: 500px;
		background-image: url("granola-1000.jpg");
	}
	#hero h1 {
		font-size: 90px;
	}
	#hero p {
		font-size: 20px;
	}
	#shop-now-btn-lg {
		display: inline-block;
	}
	#shop-now-btn-sm {
		display: none;
	}
	#buy-button h1 {
		margin-bottom: 70px;
	}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
	#hero {
		background-image: url("granola-1200.jpg");
	}
	.blurb-box {
		flex-direction: row;
	}
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	#hero {
		padding-top: 180px;
		height: 600px;
		background-image: url("granola-2000.jpg");
	}
}

@media (min-width: 2000px) {
	#hero {
		background-image: url("granola-3000.jpg");
	}
}
